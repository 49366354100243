.custom-button {
  position: relative;
  width: 100%;
  color: white;
  background: #34b3d2;
  border: none;
  border-radius: 4px;
  padding: 4px;
  box-shadow: #99c6d1;
  transition: background-color 0.1s ease-out;
  &:hover {
    background: #0282a2;
    cursor: pointer;
  }
  &.fixed-width {
    margin: 0px auto;
    width: 60%;
    position: relative;
    color: white;
    background: #34b3d2;
    border: none;
    border-radius: 4px;
    padding: 4px;
    box-shadow: #99c6d1;
    transition: background-color 0.1s ease-out;
    &:hover {
      background: #0282a2;
    }
  }
  &.discount {
    margin-right: auto;
    width: 100%;
    position: relative;
    color: white;
    background: #34b3d2;
    border: none;
    border-radius: 4px;
    padding: 4px;
    box-shadow: #99c6d1;
    transition: background-color 0.1s ease-out;
    &:hover {
      background: #0282a2;
    }
  }
  &.btn-checkout {
    width: auto;
    background: #337ab7;
    font-size: 25px;
    padding: 10px 16px;
    &:hover {
      background: #286090;
    }
  }
}
