 .desc{
   padding-bottom:40px;
 }

 .MuiTableCell-sizeSmall{
   padding-left:0 !important;
 }

 .MuiTypography-subtitle1{
   font-weight:bold !important;
 }

 .no_product_container {
   height: 500px;
 }